<template>
  <div v-bind:class="{inline: inline}" class="formInputTextarea">
    
    <textarea :id="id" type="text" :name="name" v-model="inputValue" v-bind:class="{error: !valid && touched && blurred, content: value != null && value != ''}" v-on:input="touched = true, checkValidation()" @blur="blurred = true, checkValidation()" ></textarea>
    <label v-if="label" :for="id">{{label}}</label>
  </div><!--END formInputText -->
</template>

<script>
export default {
  name: "FormInputTextarea",
  data: function() {
    return {
      touched: false,
      blurred: false,
      valid: false
    }
  },
  props: {
    name: { type: String },
    id: { type: String },
    label: { type: String },
    value: { type: String },
    validation: { type: String, default: "none" },
    inline: {type: Boolean, default: false}
  },
  mounted() {
    if(this.value != null && this.value !=""){
      this.checkValidation();
    }
  },
  computed: {
    inputValue: {
      get () {
        if(this.touched){
          this.checkValidation();
        }
        return this.value;
      },
      set (newValue) {        
        this.$emit('input', newValue);
        this.checkValidation();
      }
    }
  },
  methods: {
    checkValidation() {
      this.touched = true;
      this.blurred = true;
      if(this.validation ==="none") {
        this.valid = true;
      } else if(this.validation ==="required") {
        if(this.value != null && this.value.length > 0) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else {
        this.valid = false;
      }

      /* Emit if valid or not */
      if(this.valid) {
        this.$emit('valid', this.valid, event);
      }else {
        this.$emit('notValid', this.valid, event);
      }

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.formInputTextarea {
  position: relative;
  width: 100%;

  textarea{
    box-sizing: border-box;
    width: 100%;
    height: 8em;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline:none;
  }
  .error {
    border: 1px solid red;
  }

  textarea:focus + label,
  textarea.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size:12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }
  

}
</style>