<template>
  <div class="help contentWrapper">
    <h1>Help</h1>
    <threeWide>
      <link-card
        label="Downloads"
        boldLabel
        description="Software &amp; Manuals"
        img="icon-download.png"
        btnLink="/downloads/"
        btnLabel="View Downloads"
      ></link-card>
      <link-card
        label="Forums"
        boldLabel
        description="Join the Community"
        img="icon-forums.png"
        btnLink="https://forums.naturalpoint.com/viewforum.php?f=72"
        v-bind:externalLink="true"
        btnLabel="Visit the Forums"
      ></link-card>
      <link-card
        label="Documentation"
        boldLabel
        description="Setup Guides"
        img="icon-docs-solid-light-transparent.svg"
        btnLink="https://docs.trackir.com/"
        v-bind:externalLink="true"
        btnLabel="Read the Docs"
      ></link-card>
    </threeWide>

    <h2 class="belt"><span>Contact</span></h2>

    <!-- Response after form sent -->
    <div v-if="formSent && formResponse" class="userMessageWrapper">
      <h2>Message received</h2>
      <p>Thank you for contacting us</p>
    </div>
    <!--END form sent message-->

    <!-- Processing Form -->
    <div v-if="formSent && !formResponse" class="userMessageWrapper">
      <img
        class="loadingSpinner"
        :src="imagesUrl + 'spinner.svg'"
        alt="Loading Spinner"
      />
      <p>Processing your request</p>
    </div>
    <!--END Processing Form-->

    <form ref="contactForm" action="https://planar.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D300000000Ymn" method="POST" >

    <div v-if="!formSent" class="colsWrapper">
      <div class="col">
        <div class="twoCol">
          <form-input-text
            label="First Name*"
            ref="contactFirstName"
            :value="contact.firstname"
            v-on:input="contactFirstNameChange"
            v-on:valid="validate('firstnameValid', true)"
            v-on:notValid="validate('firstnameValid', false)"
            id="firstName"
            tabIndex="1"
            name="firstName"
            validation="required"
          >
          </form-input-text>
          <form-input-text
            label="Last Name*"
            ref="contactLastName"
            :value="contact.lastname"
            v-on:input="contactLastNameChange"
            v-on:valid="validate('lastnameValid', true)"
            v-on:notValid="validate('lastnameValid', false)"
            id="lastName"
            tabIndex="2"
            name="lastName"
            validation="required"
          >
          </form-input-text>
        </div><!--END twoCol-->

          <form-input-text
            label="Email*"
            ref="contactEmail"
            :value="contact.email"
            v-on:input="contactEmailChange"
            v-on:valid="validate('emailValid', true)"
            v-on:notValid="validate('emailValid', false)"
            id="email"
            tabIndex="3"
            name="email"
            validation="email"
          >
          </form-input-text>

        <div class="twoCol">
          <form-input-text
            label="Phone"
            :value="contact.phone"
            v-on:input="contactPhoneChange"
            id="phone"
            tabIndex="4"
            name="phone"
          >
          </form-input-text>
          <form-input-select
            id="00N1T00000AvRf1"
            label="Country*"
            ref="contactCountry"
            :value="contact.country"
            v-on:change="contactCountryChange"
            v-on:valid="validate('countryValid', true)"
            v-on:notValid="validate('countryValid', false)"
            name="00N1T00000AvRf1"
            validation="required"
            tabIndex="5"
          >
            <option value="United States">United States</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Aland Islands">Aland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua Barbuda">Antigua Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian O. Terr">British Indian O. Terr</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cacirc;te D&#39;Ivoire">Cacirc;te D&#39;Ivoire</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D&#39;Ivoire">Cote D&#39;Ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands">Falkland Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French SN Terr">French SN Terr</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard and McDonald Is.">Heard and McDonald Is.</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong SAR">Hong Kong SAR</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Ivory Coast">Ivory Coast</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Dem People&#39;s Republic">Korea, Dem People&#39;s Republic</option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kosovo">Kosovo</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mexico">Mexico</option>
            <option value="Fed St. Micronesia">Fed St. Micronesia</option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="N. Mariana Islands">N. Mariana Islands</option>
            <option value="North Korea">North Korea</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Authority">Palestinian Authority</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="St. Kitts and Nevis">St. Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
            <option value="St. Vincent and Grenadines">St. Vincent and Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Prinipe">Sao Tome and Prinipe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Scotland">Scotland</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Korea">South Korea</option>
            <option value="S. Georgia and S. Sandwich Is.">S. Georgia and S. Sandwich Is.</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Timor Leste">Timor Leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks And Caicos Is.">Turks And Caicos Is.</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekitstan">Uzbekitstan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands">Virgin Islands</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Yugoslavia">Yugoslavia</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </form-input-select>
        </div><!--END twoCol-->

        <div class="twoCol">
          <form-input-select
            id="00NS60000026vLq"
            label="Product*"
            ref="contactProduct"
            :value="contact.product"
            v-on:change="contactProductChange"
            v-on:valid="validate('productValid', true)"
            v-on:notValid="validate('productValid', false)"
            name="00NS60000026vLq"
            validation="required"
            tabIndex="7"
          >
            <option value="TrackIR3">TrackIR3</option>
            <option value="TrackIR4">TrackIR4</option>
            <option value="TrackIR5">TrackIR5</option>
            <option value="SmartNav">SmartNav</option>
            <option value="Other">Other</option>
          </form-input-select>
          <form-input-text
            label="Serial Number"
            :value="contact.serial"
            v-on:input="contactSerialChange"
            id="00NS60000026vJ3"
            tabIndex="8"
            name="00NS60000026vJ3"
          >
          </form-input-text>
        </div><!--END twoCol-->
      </div>
      <!--END col-->
      <div class="col">

        <form-input-select
          id="type"
          label="Request Type*"
          ref="contactType"
          :value="contact.type"
          v-on:change="contactTypeChange"
          v-on:valid="validate('typeValid', true)"
          v-on:notValid="validate('typeValid', false)"
          name="type"
          tabIndex="9"
          validation="required"
        >
          <option value="Technical Support">Technical support</option>
          <option value="Replacement parts requests or returns">Replacement parts request or returns</option>
          <option value="Sales or order inquiries">Sales or order inquiries</option>
        </form-input-select>

        <div class="twoCol">
          <form-input-text
            label="Subject*"
            ref="contactSubject"
            :value="contact.subject"
            v-on:input="contactSubjectChange"
            v-on:valid="validate('subjectValid', true)"
            v-on:notValid="validate('subjectValid', false)"
            id="subject"
            name="subject"
            validation="required"
            tabIndex="10"
          >
          </form-input-text>
          <form-input-text
            label="Software Version"
            :value="contact.version"
            v-on:input="contactVersionChange"
            id="00NS60000026vJ8"
            tabIndex="11"
            name="00NS60000026vJ8"
          >
          </form-input-text>
        </div>
        <!--END twoCol-->
        <form-input-textarea
          label="Describe how we can help*"
          ref="contactDescription"
          :value="contact.description"
          v-on:input="contactDescriptionChange"
          v-on:valid="validate('descriptionValid', true)"
          v-on:notValid="validate('descriptionValid', false)"
          id="description"
          validation="required"
          name="description"
          tabIndex="12"
        >
        </form-input-textarea>
        <input type=hidden name="orgid" value="00D300000000Ymn">
        <input type=hidden name="retURL" :value="returnURL">
        <input type=hidden name="recordType" id="recordType" value="012S6000001WeOL">
        <input type=hidden name="00NS60000026vJ5" id="00NS60000026vJ5" value="TrackIR">
        <input ref="fullname" type=hidden name="name" id="name" :value="contact.firstname + ' ' + contact.lastname" >

        <!-- State -->
        <input type=hidden name='00N1T00000AvRf6' id='00N1T00000AvRf6' value=''>
        <input
          id="submitBtn"
          type="submit"
          class="submitBtn"
          value="Submit"
          @click.prevent="validateForm()"
        />
      </div>
      <!--END col-->
    </div>
    <!--END colsWrapper-->
    </form>
  </div>
  <!--END contentWrapper-->
</template>
<script>
import { IMAGES_URL } from "@/constants/";
import { mapState } from "vuex";
import { SET_CONTACT_FIRSTNAME } from "../store/mutation-types";
import { SET_CONTACT_LASTNAME } from "../store/mutation-types";
import { SET_CONTACT_EMAIL } from "../store/mutation-types";
import { SET_CONTACT_PHONE } from "../store/mutation-types";
import { SET_CONTACT_COUNTRY } from "../store/mutation-types";
import { SET_CONTACT_PRODUCT } from "../store/mutation-types";
import { SET_CONTACT_SERIAL } from "../store/mutation-types";
import { SET_CONTACT_TYPE } from "../store/mutation-types";
import { SET_CONTACT_SUBJECT } from "../store/mutation-types";
import { SET_CONTACT_VERSION } from "../store/mutation-types";
import { SET_CONTACT_DESCRIPTION } from "../store/mutation-types";
import { SET_CONTACT_IP } from "../store/mutation-types";
import ThreeWide from "@/components/Layouts/ThreeWide.vue";
import LinkCard from "@/components/LinkCard.vue";
import FormInputText from "@/components/Inputs/FormInputText";
import FormInputSelect from "@/components/Inputs/FormInputSelect";
import FormInputCountry from "@/components/Inputs/FormInputCountry";
import FormInputTextarea from "@/components/Inputs/FormInputTextarea";
export default {
  name: "help",
  data: function () {
    return {
      formSent: false,
      formValid: false,
      firstnameValid: false,
      lastnameValid: false,
      emailValid: false,
      countryValid: false,
      productValid: false,
      typeValid: false,
      subjectValid: false,
      descriptionValid: false,
      formResponse: false,
      returnURL: window.location.href + "#form-sent",
    };
  },
  computed: {
    ...mapState({
      contact: (state) => state.contact.formData,
      contactSummaryData: (state) => state.contactSummary,
    }),
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    ThreeWide,
    LinkCard,
    FormInputText,
    FormInputSelect,
    FormInputCountry,
    FormInputTextarea,
  },
  mounted() {
    //gets the clients ip address
    this.fetchIp();

    let urlHash = location.hash;
    if(urlHash === "#form-sent") {
      this.formSent = true;
      this.formResponse = true;
    }
  },
  methods: {
    contactFirstNameChange: function (newValue) {
      this.$store.commit(SET_CONTACT_FIRSTNAME, newValue);
    },
    contactLastNameChange: function (newValue) {
      this.$store.commit(SET_CONTACT_LASTNAME, newValue);
    },
    contactEmailChange: function (newValue) {
      this.$store.commit(SET_CONTACT_EMAIL, newValue);
    },
    contactPhoneChange: function (newValue) {
      this.$store.commit(SET_CONTACT_PHONE, newValue);
    },
    contactCountryChange: function (newValue) {
      this.$store.commit(SET_CONTACT_COUNTRY, newValue);
    },
    contactProductChange: function (newValue) {
      this.$store.commit(SET_CONTACT_PRODUCT, newValue);
    },
    contactSerialChange: function (newValue) {
      this.$store.commit(SET_CONTACT_SERIAL, newValue);
    },
    contactTypeChange: function (newValue) {
      this.$store.commit(SET_CONTACT_TYPE, newValue);
    },
    contactSubjectChange: function (newValue) {
      this.$store.commit(SET_CONTACT_SUBJECT, newValue);
    },
    contactVersionChange: function (newValue) {
      this.$store.commit(SET_CONTACT_VERSION, newValue);
    },
    contactDescriptionChange: function (newValue) {
      this.$store.commit(SET_CONTACT_DESCRIPTION, newValue);
    },
    contactIpChange: function (newValue) {
      this.$store.commit(SET_CONTACT_IP, newValue);
    },



    //checks if all inputs are valid
    validate: function (validProp, valid) {

      // sets checkout property if input value is valid
      this[validProp] = valid;

      /* Check for valid form */
      if (
        this.firstnameValid &&
        this.lastnameValid &&
        this.emailValid &&
        this.countryValid &&
        this.productValid &&
        this.typeValid &&
        this.subjectValid &&
        this.descriptionValid
      ) {
        this.formValid = true;
      } else {
        this.formValid = false;
      }
    },
    validateAllInputs: function () {
      // check for validation on all required inputs
      if (this.$refs.contactFirstName) {
        this.$refs.contactFirstName.checkValidation();
      }
      if (this.$refs.contactLastName) {
        this.$refs.contactLastName.checkValidation();
      }
      if (this.$refs.contactEmail) {
        this.$refs.contactEmail.checkValidation();
      }
      if (this.$refs.contactCountry) {
        this.$refs.contactCountry.checkValidation();
      }
      if (this.$refs.contactProduct) {
        this.$refs.contactProduct.checkValidation();
      }
      if (this.$refs.contactType) {
        this.$refs.contactType.checkValidation();
      }
      if (this.$refs.contactSubject) {
        this.$refs.contactSubject.checkValidation();
      }
      if (this.$refs.contactDescription) {
        this.$refs.contactDescription.checkValidation();
      }
    },
    fetchIp: function () {

      fetch("https://api.db-ip.com/v2/free/self")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.contactIpChange(data);
        });
    },

    validateForm: function () {
      this.validateAllInputs();
      if(this.formValid) {
        this.$refs.contactForm.submit();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_layouts.scss";

h2 {
  margin-bottom: 0px;
}

.belt {
  line-height: 0.5;
  text-align: center;
  margin: 80px 0;

  span {
    display: inline-block;
    position: relative;
  }
}
.belt span:before,
.belt span:after {
  content: "";
  position: absolute;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  top: 50%;
  width: 200px;
}
.belt span:before {
  right: 100%;
  margin-right: 65px;
}
.belt span:after {
  left: 100%;
  margin-left: 65px;
}
.help {
  text-align: center;

  h1 {
    margin-bottom: 0px;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 0px;
  }
  p {
    margin: 0px;
  }

  ::v-deep .productCard .productImg img {
    width: 120px;
  }
}

.userMessageWrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 0 40px;
  margin-bottom: 80px;

  .loadingSpinner {
    margin-top: 40px;
  }
  h2 {
    margin-bottom: 20px;
  }
}

.colsWrapper {
  .col {
    .formInputText,
    .formInputSelect,
    .formInputTextarea {
      margin-bottom: 30px;
    }
  }
}

.selectWrapper {
  position: relative;
  width: 100%;

  /* Change autofill color ;) */
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #bd6f01 inset !important;
    border: 1px solid #c07100 !important;
  }
  /*Change text in autofill textbox*/
  select:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
  }

  select {
    box-sizing: border-box;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 8px 3px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;

    optgroup {
      color: #000000;
    }
    optgroup option,
    option {
      color: #000000;
      font-size: 12px;
    }
  }

  .error {
    border: 1px solid red;
  }

  select:focus + label,
  select.content + label {
    top: 3px;
    font-size: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 8px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    font-size: 12px;

    @include for-medium-up {
      font-size: 16px;
    }
  }
}

.submitBtn {
  transition: all $default-transition-duration $default-transition-function;
  padding: 10px 52px;
  border: solid 1px var(--color-text-default);
  border-radius: $border-radius-large;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  margin: 0px 2px 40px;
  background-color: transparent;

  &:hover {
    color: var(--color-text-emphasized);
    background: var(--color-input-darken);
    cursor: pointer;
  }
}

</style>
